import React from "react"
import QuestionnaireApp from "../QuestionnaireApp"

const Onboarding = ({ url }) => (
  <QuestionnaireApp
    basepath="/myaccount/onboarding/"
    type="private"
    url={url}
  />
)

export default Onboarding
