import React from "react"
import Onboarding from "../../../components/Onboarding"
import PrivateRoute from "../../../components/auth/PrivateRoute/loadable"

const OnboardingPage = props => (
  <PrivateRoute url={props.location.href}>
    <Onboarding url={props.location.href} />
  </PrivateRoute>
)

export default OnboardingPage
